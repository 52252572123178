<template>
  <div>
    <VueContentHolder class="content-wrapper" :padding="[20, 20, 0]" v-if="headerData">
      <div class="landing">
        <BrandLogo :width="60" :height="60" :image="partnerImage" />
        <div class="right-area">
          <VueHeadline level="2" weightLevel="6" color="grey-40" class="headline-text">
            {{ headerData.title }}
          </VueHeadline>
          <VueText sizeLevel="3" color="grey-30" class="text">
            <div>{{ headerData.description }}</div>
          </VueText>
        </div>
      </div>
      <div v-for="(info, key) in managerInfo" :key="key" class="people-wrapper">
        <BrandMeetPerson
          v-if="info"
          :person="info"
          :hrefCall="info.phone"
          :cornerIcon="icons.phoneAlt"
        />
      </div>
    </VueContentHolder>

    <div class="tabs">
      <div>
        <BrandButton
          @click="setActiveTab('visitDays')"
          :outlined="activeTab !== 'visitDays'"
          class="tab-btn"
        >
          <VueText weightLevel="4" sizeLevel="3">ZİYARET GÜNLERİM</VueText>
        </BrandButton>
      </div>

      <div>
        <BrandButton
          @click="setActiveTab('orderDays')"
          :outlined="activeTab !== 'orderDays'"
          class="tab-btn"
        >
          <VueText weightLevel="4" sizeLevel="3">ÖN SİPARİŞ GÜNLERİM</VueText>
        </BrandButton>
      </div>
    </div>

    <div class="days-table">
      <div v-for="(day, index) in DAYS" :key="index" class="day">
        <div class="day-name">{{ getDayName(day) }}</div>
        <div class="day-check">
          <VueIcon
            v-if="isChecked(day, partners[0])"
            :iconName="icons.iconChecked.name"
            :width="icons.iconChecked.width"
            :height="icons.iconChecked.height"
          />
        </div>
      </div>
    </div>

    <div class="list-button">
      <router-link :to="{ path: productAndPriceListButton.path }" @click.native="dataLayerEvent()">
        <span class="pmi-menu-text">{{ productAndPriceListButton.text }}</span>
      </router-link>
    </div>

    <VueContentHolder class="content-wrapper" :padding="[20]">
      <VueHeadline class="products-headline" level="3" weightLevel="3">Kategoriler</VueHeadline>
      <div class="products-wrapper">
        <BrandProduct
          v-for="category in categories"
          :key="category.pmCode"
          :productName="category.name"
          :productPath="`horizon/${slugify(category.name)}/${category.id}`"
          :productImageSrc="category.image"
        />
      </div>
    </VueContentHolder>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted, onBeforeMount } from 'vue';
import dateUtils from '@/mixins/dateUtils';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import BrandMeetPerson from '@/components/brand/BrandMeetPerson/BrandMeetPerson.vue';
import BrandProduct from '@/components/brand/BrandProduct/BrandProduct.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { Pladis, User } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';
import { getPartnerImage } from '@/utils/partnerImageUtils';
import { slugify } from '@/utils/stringUtils';
import { BUSINESS_PARTNERS } from '@/constants/businessPartners.constants';
import { DAYS } from '@/constants/days.constants';

export default {
  name: 'Home',
  components: {
    BrandLogo,
    VueHeadline,
    VueText,
    VueContentHolder,
    BrandMeetPerson,
    BrandProduct,
    VueIcon,
    BrandButton,
  },
  mixins: [dateUtils],
  setup() {
    const partnerImage = getPartnerImage('horizon');
    const headerData = ref(null);
    const categories = ref([]);
    const partners = ref([]);
    const activeTab = ref('visitDays');
    const productAndPriceListButton = reactive({
      id: 0,
      text: 'Güncel Ürün ve Fiyat Listesi',
      icon: ICON_VARIABLES['sheetTable'],
      path: 'horizon/price-list',
    });

    const icons = computed(() => ICON_VARIABLES);
    const managerInfo = computed(() => ({
      salesManagerInfo: headerData.value?.salesManagerInfo,
      businessDeveleopmentManagerInfo: headerData.value?.businessDeveleopmentManagerInfo,
    }));

    const dataLayerEvent = () => {
      gtmUtils.pushDataLayerEvent('fiyat_listesi_click', {
        location: 'business-partners',
        partner: 'HORIZON',
      });
    };

    const getRouteDays = () => {
      User.getRouteDays()
        .then(res => {
          if (res.data?.Data) {
            partners.value = res.data.Data.filter(
              f => f.partnerName === BUSINESS_PARTNERS.HORIZON.partnerName,
            );
          }
        })
        .catch(err => console.error(err));
    };

    const setActiveTab = tab => {
      if (activeTab.value !== tab) {
        activeTab.value = tab;
      }
    };

    const isChecked = (day, partner) => {
      if (activeTab.value === 'visitDays') {
        return partner?.routeDays.includes(day);
      } else {
        return partner?.routeOrderDays.includes(day);
      }
    };

    const getCategories = () => {
      Pladis.getCategories(BUSINESS_PARTNERS.HORIZON.partnerCode).then(response => {
        if (response.data?.Data) {
          categories.value = response.data.Data;
        }
      });
    };

    const getHeader = () => {
      Pladis.getHeader(BUSINESS_PARTNERS.HORIZON.partnerCode).then(response => {
        if (response.data?.Data) {
          headerData.value = formatHeaderData(response.data.Data);
        }
      });
    };

    const formatHeaderData = data => {
      return {
        ...data,
        salesManagerInfo: data.salesManagerInfo
          ? formatManagerInfo(data.salesManagerInfo, 'Satış Plasiyeri')
          : null,
        businessDeveleopmentManagerInfo: data.businessDeveleopmentManagerInfo
          ? formatManagerInfo(data.businessDeveleopmentManagerInfo, 'Satış Şefi')
          : null,
      };
    };

    const formatManagerInfo = (info, title) => {
      return {
        name: info.name?.trim().toLocaleUpperCase('tr-TR') || '-',
        title,
        phone: info.phone_Number ? `+90${info.phone_Number.trim()}` : '',
      };
    };

    onMounted(() => {
      getCategories();
      getHeader();
    });

    onBeforeMount(() => {
      getRouteDays();
    });

    return {
      partnerImage,
      partners,
      headerData,
      categories,
      activeTab,
      DAYS,
      productAndPriceListButton,
      icons,
      managerInfo,
      setActiveTab,
      isChecked,
      slugify,
      dataLayerEvent,
    };
  },
};
</script>

<style scoped lang="scss">
.content-row {
  height: unset;
  padding-left: 20px;
}
.gray-bg {
  background: #f8f9f9;
  margin-bottom: 60px;
}
.landing {
  display: flex;
  padding-bottom: palette-space-level(15);
  .right-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 18px;

    .headline-text {
      margin-bottom: palette-space-level(10);
    }
    .text {
      line-height: 1.27;
      padding-right: palette-space-level(10);
    }

    .link-holder {
      display: flex;
      flex-direction: row;
      align-items: space-between;

      .left-link {
        padding-right: palette-space-level(30);
      }
      .left-link,
      .right-link {
        padding-top: palette-space-level(10);
        line-height: 1.27;
        text-decoration: underline;
      }
    }
  }
}
.people-wrapper {
  margin-top: 10px;
}
.list-button {
  margin-top: 20px;
  margin-left: 20px;
  display: block;
  height: 35px;
  width: max-content;
  line-height: 35px;
  padding: 0 20px;
  text-align: center;
  border-radius: palette-radius-level('15');
  background-image: linear-gradient(to left, #d20051, #ffa300);
  margin-right: 15px;
  text-decoration: none;
  color: #ffffff;
  a {
    color: #ffffff;
    font-size: 14px;
    text-decoration: none;
  }
}
.icon-style {
  display: inline-block;
  position: relative;
  top: 6px;
  left: -10px;
}
.grid-row {
  gap: 20px;
}
.products-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 21px;
  grid-row-gap: 40px;
  /deep/ .product-img {
    padding: 10px;
  }
}
.products-headline {
  padding: 0 0 10px 0;
}
.products-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: palette-space-level('20');
  margin-bottom: 40px;
}
.tabs {
  display: flex;
  justify-content: center;
  margin-top: palette-space-level(25);
  z-index: 5;
  & > div {
    &:first-child {
      margin-right: palette-space-level(15);
      @media screen and (max-width: 325px) {
        margin-right: palette-space-level(10);
      }
    }
  }
  .tab-btn {
    padding: 5px 10px;
  }
}

.days-table {
  margin-top: palette-space-level(20);
  margin-left: palette-space-level(20);
  margin-right: palette-space-level(20);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  > div {
    &:first-child {
      border-left: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    }
  }
}
.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-top: 1px solid transparentize(palette-color-level('grey', 20), 0.5);

  &-name {
    background-color: palette-color-level('grey', 10);
    height: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    color: palette-color-level('grey', 30);
  }
  &-check {
    height: 101px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
    border-right: 1px solid transparentize(palette-color-level('grey', 20), 0.5);
  }
}
.info-text {
  padding-left: palette-space-level(20);
  padding-right: palette-space-level(20);
}
.weekly-wrapper {
  margin-bottom: palette-space-level(50);
}
</style>
